import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Components
import DataTable from '../../components/DataTable';
import InterviewDetails from '../../components/popup/InterviewDetails';
import { ViewButton, ActionButtonsForInterviewRound } from '../../components/ActionButtons';
import Label from '../../components/Label';
import CustomButton from '../../components/CustomButton';
import LogDetails from '../../components/popup/LogDetails';
import { TextAreaWithCounterLimit } from '../../components/TextAreaWithCounterLimit';

// Prime-React Components
import { Badge } from 'primereact/badge';
import { classNames } from 'primereact/utils';
import { ProgressBar } from 'primereact/progressbar';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { confirmDialog } from 'primereact/confirmdialog';

// Constants
import { INTERVIEW_ROUND_COLUMNS } from '../../constants/tableColumns';
import {
  INTERVIEW_TYPE,
  STATUS_INTERVIEW,
  INTERVIEW_STATUS_LIST,
  OVERALL_FEEDBACK,
  OVERALL_GRADE,
  HR_OVERALL_FEEDBACK,
  LABEL_TYPES,
  MSG_TYPES,
  BUTTON_TYPES,
  ACTION_TYPE,
  TABLE_NAME,
  TEXTAREA_LIMIT,
  FEEDBACK_TEXTAREA_LIMIT,
  STATUS,
  HR_OVERALL_FEEDBACK_STATUS,
  InterviewType,
  compareValues,
  RENDER,
  ROLES,
  PRACTICAL_RESULT_STATUS,
  INTERVIEW_MODE
} from '../../constants/common';

// Utils
import { formatTime, getFormattedDateView } from '../../utils/date';
import { openResumeLink, renderHrAvatar, wordCapitalize } from '../../utils/common';

// Redux-Slices
import { getNotification } from '../../redux/slices/notification';

// Redux Actions
import {
  interviewDetailAction,
  interviewerFeedbackDetailAction,
  overallIintervieweFeedbackDetailAction
} from '../../redux/actions/userDetails';
import { rejectCandidateInterviewRound, updateFeedback } from '../../redux/actions/interviews';
import { getJobDesignationBySearch } from '../../redux/actions/hr';

// Apis
import AddEditInterviewRound from './AddEditInterviewRound';
import { InputText } from 'primereact/inputtext';
import { getInterviewDetails } from '../../redux/actions/interviews';
import AddHrFeedback from '../../components/popup/AddHrFeedback';
import ConfirmDialogWithReason from '../../components/ConfirmDialogWithReasonForInterview';
import AddEditFeedback from '../../components/popup/AddEditFeedback';
import ReactTooltip from 'react-tooltip';
import ScheduleDetails from '../../components/popup/ScheduleDetails';

const pathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/resume`;

const InterviewRounds = () => {
  // React router dom hooks.
  const { ID } = useParams();
  const navigate = useNavigate();

  // Redux hooks.
  const dispatch = useDispatch();
  const { userDetails, auth, interviews } = useSelector((state) => state);
  const { isNotification } = useSelector((state) => state.notification);
  const isInterviewRoundCancelled = useSelector((state) => state.interviews?.isCancelled);
  const { overallFeedback } = useSelector((state) => state.userDetails);
  const { jobDesignationData } = useSelector((state) => state.hrs);
  const { searchData } = useSelector((state) => state.hrs);

  // React useState hooks.
  const [editInterviewId, setEditInterviewId] = useState(null);
  const [interviewList, setInterviewList] = useState([]);
  const [interviewExport, setInterviewExport] = useState([]);
  const [interviewRoundId, setinterviewRoundId] = useState(null);
  const [hrRoundId, setHrRoundId] = useState(null);
  const [interviewDetails, setInterviewDetails] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showLogPopup, setShowLogPopup] = useState(false);
  const [viewFeedbackDetailsId, setViewFeedbackDetailsId] = useState(null);
  const [viewFeedbackDetailsStatus, setViewFeedbackDetailsStatus] = useState(null);
  const [showViewFeedbackPopup, setShowViewFeedbackPopup] = useState(false);
  const [tableStates, setTableStates] = useState({
    orderBy: 'ASC',
    interviewID: ID,
    interviewRoundStatus: '',
    interviewRoundType: ''
  });
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [isUpdateFeedback, setIsUpdateFeedback] = useState(false);
  const [designationOptions, setdesignationOptions] = useState(null);
  const [designationText, setDesignationText] = useState('');
  const designation = userDetails?.JobDesignation?.Designation;
  const [feedbackData, setFeedbackData] = useState({
    Ratings: [],
    result: '',
    overallGrade: '',
    codeQuality: '',
    taskCompletion: '',
    feedbackNote: '',
    interviewRoundId: '',
    jobDesignation: designation,
    package: 0,
    yearsOfExperience: 0,
    monthsOfExperience: 0,
    candidateExpectation: 0
  });
  const [typeInterview, setTypeInterview] = useState(null);
  const [interviewRoundMode, setInterviewRoundMode] = useState(null);
  const [visibleRight, setVisibleRight] = useState(false);
  const [scheduleDetailPopup, setScheduleDetailPopup] = useState(false);
  const [selectedRound, setSelectedRound] = useState(null);

  // React useState hooks To cancel the interview round
  const [interviewId, setInterviewId] = useState(null);
  const [candidateName, setCandidateName] = useState(null);
  const [cancelConfirmBoxVisibility, setCancelConfirmBoxVisibility] = useState(false);

  const onToggleSiderbar = (value) => setVisibleRight(value);

  const onTogglePopup = () => setShowPopup((prevState) => !prevState);

  const onViewFeedbackTogglePopup = () => setShowViewFeedbackPopup((prevState) => !prevState);

  const onToggleLogsPopup = () => setShowLogPopup((prevState) => !prevState);

  const onToggleFeedbackPopup = (value) => setShowFeedbackPopup(value);

  const submitFeedbackOnConfirmation = (values) => {
    let formData = {
      interviewId: ID,
      result: values.result.toString(),
      feedbackNote: values.feedbackNote,
      overallGrades: values?.overallGrade ? values.overallGrade.toString() : '',
      designation: values?.jobDesignation || '',
      packagePerAnum: values?.package ? values.package.toString() : '',
      yearsOfExperience: values?.yearsOfExperience ? values.yearsOfExperience.toString() : '',
      monthsOfExperience: values?.monthsOfExperience ? values.monthsOfExperience.toString() : '',
      candidateExpectations: values?.candidateExpectation
        ? values.candidateExpectation.toString()
        : '',
      interviewType: InterviewType['HR Round']
    };
    dispatch(updateFeedback(formData, ID));
    // Fetch the latest data after the submitting the feedback to get updated feedback data for the HR feedback.
    dispatch(interviewDetailAction(tableStates));
  };

  const confirmActionOnSubmitInterviewFeedback = (values) => {
    confirmDialog({
      header: 'Confirmation',
      message: (
        <>
          Are you sure you want to submit the overall feedback for{' '}
          <strong>
            {userDetails &&
              userDetails?.Candidate &&
              wordCapitalize(userDetails?.Candidate?.FirstName) +
                ' ' +
                wordCapitalize(userDetails?.Candidate?.LastName)}{' '}
          </strong>
          candidate?
        </>
      ),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        submitFeedbackOnConfirmation(values);
      },
      reject: () => {}
    });
  };

  // Formik for the validation.
  const formik = useFormik({
    initialValues: feedbackData,
    validationSchema: Yup.object().shape({
      result: Yup.string().required('Please enter result'),
      overallGrade: Yup.string().when('result', {
        is: HR_OVERALL_FEEDBACK_STATUS['Not Selected'],
        then: Yup.string(),
        otherwise: Yup.string().required('Please select an overall grade')
      }),
      jobDesignation: Yup.string().when('result', {
        is: HR_OVERALL_FEEDBACK_STATUS['Not Selected'],
        then: Yup.string(),
        otherwise: Yup.string().required('Please select a designation')
      }),
      package: Yup.string().when('result', {
        is: HR_OVERALL_FEEDBACK_STATUS['Not Selected'],
        then: Yup.string(),
        otherwise: Yup.string()
          .typeError('package must be a number')
          .required('Please enter package value')
      }),
      yearsOfExperience: Yup.number().when('result', {
        is: HR_OVERALL_FEEDBACK_STATUS['Not Selected'],
        then: Yup.number(),
        otherwise: Yup.number()
          .min(0, 'Minimum 0 Year.')
          .max(100, 'Maximum 100 Years.')
          .typeError('Only numbers are allowed.')
          .required('Exp. in Years is Required.')
      }),
      monthsOfExperience: Yup.number().when('result', {
        is: HR_OVERALL_FEEDBACK_STATUS['Not Selected'],
        then: Yup.number(),
        otherwise: Yup.number()
          .min(0, 'Minimum 0 Month.')
          .max(11, 'Maximum 11 Months.')
          .required('Exp. in Months is required.')
          .typeError('Only numbers are allowed.')
      }),
      candidateExpectation: Yup.string().when('result', {
        is: HR_OVERALL_FEEDBACK_STATUS['Not Selected'],
        then: Yup.string(),
        otherwise: Yup.string()
          .typeError('candidate expectation must be a number')
          .required('Please enter candidate expectation')
      }),
      feedbackNote: Yup.string()
        .trim()
        .min(TEXTAREA_LIMIT.MIN, 'Feedback note should be at least ${min} characters.')
        .max(TEXTAREA_LIMIT.MAX, 'Feedback note should not exceed ${max} characters.')
        .nullable()
        .required('Please enter notes.')
    }),
    onSubmit: (values) => {
      confirmActionOnSubmitInterviewFeedback(values);
    },
    enableReinitialize: true
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name, additionalError = '') => {
    let response = null;
    if (isFormFieldValid(name) || additionalError) {
      response = <small className="p-error">{formik.errors[name] || additionalError}</small>;
    }
    return response;
  };

  useEffect(() => {
    if (userDetails?.isUpdated || userDetails?.isAdded) {
      dispatch(interviewDetailAction(tableStates));
    } else {
      ID && !userDetails.isFeedbackUpdated && dispatch(interviewDetailAction(tableStates));
      if (isNotification) dispatch(getNotification(false));
    }
  }, [
    dispatch,
    isNotification,
    ID,
    isUpdateFeedback,
    tableStates,
    userDetails?.isUpdated,
    userDetails?.isAdded,
    userDetails?.hasHRAddedFeedback,
    userDetails?.isFeedbackUpdated,
    isInterviewRoundCancelled
  ]);

  useEffect(() => {
    dispatch(overallIintervieweFeedbackDetailAction(ID));
  }, [ID, dispatch, userDetails?.hasHRAddedFeedback]);

  useEffect(() => {
    if (candidateName && interviewId) {
      setCancelConfirmBoxVisibility(true);
    } else {
      setCancelConfirmBoxVisibility(false);
    }
  }, [candidateName, interviewId]);

  function getInterviewerNameFromRoundMapping(InterviewRoundInvitations, isPopup) {
    const interviewers =
      InterviewRoundInvitations &&
      InterviewRoundInvitations?.length &&
      InterviewRoundInvitations.filter((item) => {
        if (item?.Status === STATUS.ACCEPT || item?.Status === STATUS.PENDING) {
          return true;
        } else return false;
      });

    if (interviewers && interviewers?.length) {
      const interviewersName = interviewers
        ?.map((item) => {
          return (
            wordCapitalize(item?.Invited?.FirstName) + ' ' + wordCapitalize(item?.Invited?.LastName)
          );
        })
        .join(', ');

      return isPopup
        ? wordCapitalize(interviewersName) + ' [Invitation Accepted]'
        : wordCapitalize(interviewersName);
    } else {
      return wordCapitalize(auth.userName);
    }
  }

  function getInterviewerNameFromInterviewInvitation(InterviewRoundInvitations, isPopup) {
    const interviewers =
      InterviewRoundInvitations &&
      InterviewRoundInvitations?.length &&
      InterviewRoundInvitations.filter((interviewerDetails) => {
        if (
          interviewerDetails?.Status === STATUS.ACCEPT ||
          interviewerDetails?.Status === STATUS.PENDING
        )
          return true;
      });
    if (interviewers && interviewers?.length) {
      let interviewersName = interviewers
        .map((interviewerDetails) => {
          return (
            interviewerDetails?.Invited?.FirstName + ' ' + interviewerDetails?.Invited?.LastName
          );
        })
        ?.join(', ');

      return isPopup
        ? interviewers?.map((item, index) => {
            return (
              <p key={index}>
                {index + 1 + '.' + ' ' + item?.Invited?.FirstName + ' ' + item?.Invited?.LastName}
                <span className="m-2"> [Invitation Sent]</span>
              </p>
            );
          })
        : wordCapitalize(interviewersName);
    } else {
      return '';
    }
  }

  // this useEffect for showing the data in datatable
  useEffect(() => {
    const modifiedInterviewRoundDetails = userDetails?.RoundOfInterview?.map(
      (interviewData, index) => {
        const candidateName = `${wordCapitalize(userDetails.Candidate.FirstName)} ${wordCapitalize(
          userDetails.Candidate.LastName
        )}`;
        return {
          ...interviewData,
          'Interviewer Name': (
            <Link
              to="#"
              className="table-view-popup-link"
              onClick={() => handleView(interviewData.ID)}>
              {getInterviewerNameFromInterviewInvitation(
                interviewData?.InterviewRoundInvitations,
                false
              ) ||
                (interviewData?.HROfRound?.FirstName
                  ? wordCapitalize(interviewData?.HROfRound?.FirstName)
                  : '') +
                  ' ' +
                  (interviewData?.HROfRound?.LastName
                    ? wordCapitalize(interviewData?.HROfRound?.LastName)
                    : '')}
            </Link>
          ),
          'Start Date': `${getFormattedDateView(
            interviewData.StartDateOfInterviewRound
          )} ${formatTime(interviewData?.StartDateOfInterviewRound)}`,
          HR: renderHrAvatar(
            interviewData?.HROfRound?.ID,
            interviewData?.HROfRound?.UserProfile,
            interviewData?.HROfRound?.FirstName,
            interviewData?.HROfRound?.LastName
          ),
          RecentFeedbackBy:
            (interviewData?.RecentFeedbackBy?.FirstName
              ? wordCapitalize(interviewData?.RecentFeedbackBy?.FirstName)
              : '') +
            ' ' +
            (interviewData?.RecentFeedbackBy?.LastName
              ? wordCapitalize(interviewData?.RecentFeedbackBy?.LastName)
              : ''),

          InterviewRound: `${INTERVIEW_TYPE.filter(
            (num) => num.code === interviewData?.InterviewRound
          ).map((type) => type.name)}`,
          InterviewRoundMode: interviewData?.InterviewRoundMode
            ? INTERVIEW_MODE.find((option) => option.code === interviewData?.InterviewRoundMode)
                ?.name
            : '',

          action: (
            <div className="flex align-items-center">
              <ActionButtonsForInterviewRound
                disableFeedback={interviewData.Status === STATUS_INTERVIEW.CANCELLED}
                disableScheduleDetails={
                  !(
                    interviewData.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_RESCHEDULE ||
                    interviewData.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_RESCHEDULE ||
                    interviewData.Status === STATUS_INTERVIEW.PRACTICAL_RESCHEDULE ||
                    interviewData.Status === STATUS_INTERVIEW.MANAGEMENT_RESCHEDULE ||
                    interviewData.Status === STATUS_INTERVIEW.HR_RESCHEDULE
                  )
                }
                disabledRejection={
                  interviewData.Status === STATUS_INTERVIEW.CANCELLED ||
                  interviewData.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_PENDING ||
                  interviewData.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_SCHEDULE ||
                  interviewData.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_RESCHEDULE ||
                  interviewData.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_PENDING ||
                  interviewData.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_SCHEDULE ||
                  interviewData.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_RESCHEDULE ||
                  interviewData.Status === STATUS_INTERVIEW.MANAGEMENT_PENDING ||
                  interviewData.Status === STATUS_INTERVIEW.MANAGEMENT_SCHEDULE ||
                  interviewData.Status === STATUS_INTERVIEW.MANAGEMENT_RESCHEDULE ||
                  interviewData.Status === STATUS_INTERVIEW.PRACTICAL_PENDING ||
                  interviewData.Status === STATUS_INTERVIEW.PRACTICAL_SCHEDULE ||
                  interviewData.Status === STATUS_INTERVIEW.PRACTICAL_RESCHEDULE ||
                  interviewData.Status === STATUS_INTERVIEW.HR_PENDING ||
                  interviewData.Status === STATUS_INTERVIEW.HR_SCHEDULE ||
                  interviewData.Status === STATUS_INTERVIEW.HR_RESCHEDULE
                }
                onClick={(actionType) =>
                  handleRowActions(
                    actionType,
                    interviewData.ID,
                    interviewData?.InterviewRound,
                    interviewData?.InterviewRoundMode,
                    candidateName,
                    interviewData?.Status,
                    interviewData
                  )
                }
                disableparameter={interviewData?.Status}
                interviewRound={interviewData?.InterviewRound}
              />
            </div>
          ),
          Status: INTERVIEW_STATUS_LIST.filter((num) => num.code === interviewData?.Status).map(
            (type) => {
              return (
                <div key={index} className="center interview-status">
                  <Badge
                    key={type.code}
                    className={`white-space-nowrap ${
                      interviewData?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_SCHEDULE ||
                      interviewData?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_SCHEDULE ||
                      interviewData?.Status === STATUS_INTERVIEW.MANAGEMENT_SCHEDULE ||
                      interviewData?.Status === STATUS_INTERVIEW.PRACTICAL_SCHEDULE ||
                      interviewData?.Status === STATUS_INTERVIEW.HR_SCHEDULE
                        ? 'schedule-badge'
                        : interviewData?.Status ===
                            STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_RESCHEDULE ||
                          interviewData?.Status ===
                            STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_RESCHEDULE ||
                          interviewData?.Status === STATUS_INTERVIEW.MANAGEMENT_RESCHEDULE ||
                          interviewData?.Status === STATUS_INTERVIEW.HR_RESCHEDULE ||
                          interviewData?.Status === STATUS_INTERVIEW.PRACTICAL_RESCHEDULE
                        ? 'reschedule-badge'
                        : interviewData?.Status ===
                            STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_COMPLETE ||
                          interviewData?.Status ===
                            STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_COMPLETE ||
                          interviewData?.Status === STATUS_INTERVIEW.MANAGEMENT_COMPLETE ||
                          interviewData?.Status === STATUS_INTERVIEW.PRACTICAL_COMPLETE ||
                          interviewData?.Status === STATUS_INTERVIEW.HR_COMPLETE
                        ? 'complete-badge'
                        : interviewData?.Status === STATUS_INTERVIEW.CANCELLED
                        ? 'cancel-badge'
                        : 'pending-badge'
                    }`}
                    value={
                      [
                        STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_RESCHEDULE,
                        STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_RESCHEDULE,
                        STATUS_INTERVIEW.PRACTICAL_RESCHEDULE,
                        STATUS_INTERVIEW.MANAGEMENT_RESCHEDULE,
                        STATUS_INTERVIEW.HR_RESCHEDULE
                      ].indexOf(interviewData?.Status) !== -1
                        ? `${type.name} -  ${
                            interviewData?.InterviewRoundInvitations.filter(
                              (child) => child.Status === STATUS.ACCEPT
                            ).length >= 1
                              ? 'Accepted'
                              : 'Pending'
                          }`
                        : `${type.name}`
                    }
                  />
                  {interviewData?.Status === STATUS_INTERVIEW.CANCELLED &&
                    interviewData?.CancellationReason && (
                      <>
                        <i
                          className="custom-target-icon pi pi-info-circle pl-1"
                          data-tip
                          data-for={`reason-tooltip-${index}`}
                          key={index}
                          style={{ cursor: 'pointer' }}
                        />
                        <ReactTooltip
                          key={index}
                          id={`reason-tooltip-${index}`}
                          aria-haspopup="true">
                          {(interviewData?.CurrentRound?.CancelledByUser?.FirstName ||
                            interviewData?.CurrentRound?.CancelledByUser?.LastName) && (
                            <p>{`Cancelled By: ${
                              wordCapitalize(interviewData?.CancelledByUser?.FirstName || '') +
                              ' ' +
                              wordCapitalize(interviewData?.CancelledByUser?.LastName || '')
                            } ${
                              interviewData?.CancelledByUser?.UserTypeId === ROLES.HR
                                ? '(HR)'
                                : interviewData?.CancelledByUser?.UserTypeId === ROLES.INTERVIEWER
                                ? '(Interviewer)'
                                : interviewData?.CancelledByUser?.UserTypeId ===
                                  ROLES.HR_AND_INTERVIEWER
                                ? '(HR and Interviewer)'
                                : ''
                            }`}</p>
                          )}
                          <p>{`Cancellation Reason: ${
                            interviewData?.CancellationReason || '--'
                          }`}</p>
                        </ReactTooltip>
                      </>
                    )}
                </div>
              );
            }
          ),
          Result: interviewData?.Result
            ? interviewData?.InterviewRound === InterviewType.Practical
              ? PRACTICAL_RESULT_STATUS[interviewData?.Result]
              : compareValues(HR_OVERALL_FEEDBACK_STATUS, interviewData?.Result.toString())
            : '',
          interviewStatus: INTERVIEW_STATUS_LIST.filter((num) => num.code === interviewData?.Status)
        };
      }
    );
    let hrRoundID;
    let StartDateOfInterviewRound = '';
    if (modifiedInterviewRoundDetails) {
      modifiedInterviewRoundDetails.forEach((interviewRound) => {
        if (
          interviewRound?.interviewStatus[0]?.code === STATUS_INTERVIEW.SCHEDULED &&
          interviewRound?.InterviewRound === INTERVIEW_TYPE[2].name
        ) {
          setinterviewRoundId(interviewRound?.ID);
        }

        if (
          interviewRound?.interviewStatus[0]?.code === STATUS_INTERVIEW.COMPLETED &&
          interviewRound?.InterviewRound === INTERVIEW_TYPE[2].name
        ) {
          if (
            StartDateOfInterviewRound &&
            StartDateOfInterviewRound < interviewRound?.StartDateOfInterviewRound
          ) {
            hrRoundID = interviewRound?.ID;
            StartDateOfInterviewRound = interviewRound?.StartDateOfInterviewRound;
          } else {
            hrRoundID = interviewRound?.ID;
            StartDateOfInterviewRound = interviewRound?.StartDateOfInterviewRound;
          }
          if (
            interviewRound.interviewStatus[0]?.code !== 2 &&
            interviewRound?.InterviewRound !== 'HR Round'
          ) {
            setHrRoundId(interviewRound?.InterviewRound === INTERVIEW_TYPE[2].name);
          }
        }
      });

      if (hrRoundID) {
        setHrRoundId(hrRoundID);
      }
    }
    setInterviewList(modifiedInterviewRoundDetails);
  }, [userDetails]);

  useEffect(() => {
    const modifiedInterviewRoundDetails = userDetails?.RoundOfInterview?.map((interviewData) => {
      const status = INTERVIEW_STATUS_LIST.filter((num) => num.code === interviewData?.Status).map(
        (item) => item.name
      )[0];
      const round = `${INTERVIEW_TYPE.filter(
        (num) => num.code === interviewData?.InterviewRound
      ).map((type) => type.name)}`;
      return {
        'Interviewer Name':
          getInterviewRoundName(interviewData?.InterviewRound) !== INTERVIEW_TYPE[2].name
            ? interviewData?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_PENDING ||
              interviewData?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_PENDING ||
              interviewData?.Status === STATUS_INTERVIEW.MANAGEMENT_PENDING ||
              interviewData?.Status === STATUS_INTERVIEW.PRACTICAL_PENDING ||
              interviewData?.Status === STATUS_INTERVIEW.HR_PENDING
              ? getInterviewerNameFromInterviewInvitation(
                  interviewData?.InterviewRoundInvitations,
                  false
                )
              : getInterviewerNameFromRoundMapping(interviewData?.InterviewRoundInvitations, false)
            : wordCapitalize(auth.userName),
        'Start Date': `${getFormattedDateView(
          interviewData.StartDateOfInterviewRound
        )} ${formatTime(interviewData?.StartDateOfInterviewRound)}`,
        InterviewRound: round,
        Status: status
      };
    });
    setInterviewExport(modifiedInterviewRoundDetails);
  }, [userDetails]);

  useEffect(() => {
    if (searchData) {
      const modifiedDesignationOptions =
        searchData && searchData.length > 0 ? searchData : [{ Designation: MSG_TYPES.NO_RESULT }];
      setdesignationOptions(modifiedDesignationOptions);
    }
  }, [searchData]);
  const displayValueTemplate = (value) => {
    return (
      <>
        {value / 20} / <b>5</b>
      </>
    );
  };
  const displayValuePercantage = (value) => {
    return (
      <>
        {value} <b>%</b>
      </>
    );
  };

  const getInterviewRoundName = (InterviewRound) => {
    return `${INTERVIEW_TYPE.filter((num) => num.code === InterviewRound).map(
      (type) => type.name
    )}`;
  };

  // below useffect for popup data
  useEffect(() => {
    const modifyUserDetails = () => {
      if (userDetails && userDetails?.RoundOfInterview) {
        const interviewRoundDetails = userDetails?.RoundOfInterview?.filter((interviewRound) => {
          if (interviewRound.ID === interviewRoundId) return true;
        });
        const {
          StartDateOfInterviewRound,
          InterviewRound,
          InterviewRoundFeedback,
          InterviewRoundInvitations,
          Status,
          Ratings,
          CancellationReason,
          CancelledAt
        } = interviewRoundDetails[0];

        const { Candidate } = userDetails;

        const getInterviewTechnologyDetails = () => {
          return userDetails?.Candidate.UMap.map((techObj) =>
            wordCapitalize(techObj?.MapT?.Technology)
          ).join(' ,');
        };

        if (Candidate) {
          const interviewDetailsObj = {
            'interview details': {
              'Interviewer Name':
                Status === STATUS_INTERVIEW.PRACTICAL_PENDING ||
                Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_PENDING ||
                Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_PENDING ||
                Status === STATUS_INTERVIEW.MANAGEMENT_PENDING ||
                Status === STATUS_INTERVIEW.HR_PENDING
                  ? getInterviewerNameFromInterviewInvitation(InterviewRoundInvitations, true)
                  : getInterviewerNameFromRoundMapping(InterviewRoundInvitations, true),

              'Interview Technology': getInterviewTechnologyDetails(),

              'Scheduled Date': `${getFormattedDateView(StartDateOfInterviewRound) || ''}
                ${formatTime(StartDateOfInterviewRound) || ''}`,
              'Interview Round': `${INTERVIEW_TYPE.filter((num) => num.code === InterviewRound).map(
                (type) => type.name
              )}`
            },
            'Feedback details': Status === STATUS_INTERVIEW.COMPLETED && {
              'Interview Feedback': `${InterviewRoundFeedback ? InterviewRoundFeedback : ''}`,
              'Over All Feedback': `${OVERALL_FEEDBACK.filter(
                (num) => num.code === Ratings?.OverAllFeedback
              ).map((type) => type.name)}`,

              'Over All Grades': `${OVERALL_GRADE.filter(
                (num) => num.code === Ratings?.OverAllGrades
              ).map((type) => type.name)}`,
              'Code Quality': `${
                InterviewRound === 2 ? (Ratings?.CodeQuality ? Ratings?.CodeQuality : '') : '' || ''
              }`,
              'Task Completion': InterviewRound === 2 &&
                Ratings?.TaskCompletion && [
                  <div key="taskcompletion" className="w-10">
                    <ProgressBar
                      value={Number(Ratings?.TaskCompletion)}
                      displayValueTemplate={displayValuePercantage}></ProgressBar>
                  </div>
                ],

              Ratings:
                Ratings?.Ratings &&
                Ratings?.Ratings?.length &&
                (InterviewRound === 1 || interviewRoundId === 3)
                  ? Ratings?.Ratings.map((tech, i) => {
                      return (
                        <div key={i} className="feedback-slider-wrap">
                          {InterviewRound === 1 || interviewRoundId === 3 ? (
                            <div>
                              <span className="slider-title"> {tech.techName}</span>
                              <ProgressBar
                                value={Number(tech.ratings) * 20}
                                displayValueTemplate={displayValueTemplate}></ProgressBar>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      );
                    })
                  : null
            }
          };
          // Assign the Cancellation Reason and Cancellation time if the interview is cancelled.
          if (Status === STATUS_INTERVIEW.CANCELLED) {
            Object.assign(interviewDetailsObj['interview details'], {
              'Interview Cancelled Time':
                CancelledAt && moment(CancelledAt).isValid
                  ? moment(CancelledAt).format('lll')
                  : '--',
              'Cancellation Reason': CancellationReason || '--'
            });
          }
          setInterviewDetails(interviewDetailsObj);
        }
      }
    };
    if (interviewRoundId && showPopup) {
      modifyUserDetails();
    }
  }, [interviewRoundId, isNotification, userDetails, hrRoundId]);

  const handleView = (ID) => {
    if (ID) {
      onTogglePopup();
      setinterviewRoundId(ID);
    }
  };

  const handleRowActions = (
    actionType,
    rowId,
    type,
    mode,
    candidateName,
    roundStatus,
    interviewData
  ) => {
    setSelectedRound(interviewData);
    if (actionType === ACTION_TYPE.FEEDBACK) {
      setCandidateName(candidateName);
      setTypeInterview(type);
      setInterviewRoundMode(mode);
      if (rowId) {
        setinterviewRoundId(rowId);
        if (type === InterviewType['HR Round']) {
          onToggleFeedbackPopup(true);
        } else {
          setShowViewFeedbackPopup(true);
          setViewFeedbackDetailsId(rowId);
        }
        setViewFeedbackDetailsStatus(roundStatus);
      }
    } else if (actionType === ACTION_TYPE.EDIT) {
      setEditInterviewId(rowId);
      dispatch(getInterviewDetails(ID));
      onToggleSiderbar(true);
      document.body.classList.add('sidebar-open');
    } else if (actionType === ACTION_TYPE.CANCEL) {
      setCandidateName(candidateName);
      setInterviewId(rowId);
    } else if (actionType === ACTION_TYPE.REJECT) {
      confirmDialog({
        message: (
          <>
            This candidate will be rejected for this Interview. Are you sure you want to reject{' '}
            <strong>{candidateName}</strong> candidate?
          </>
        ),
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          dispatch(
            rejectCandidateInterviewRound({
              interviewRoundId: rowId,
              result: HR_OVERALL_FEEDBACK_STATUS['Not Selected']
            })
          );
          setTimeout(() => {
            navigate('/hr/interviews');
          }, [1000]);
        }
      });
    } else if (actionType === ACTION_TYPE.SCHEDULE_DETAILS) {
      setEditInterviewId(rowId);
      setScheduleDetailPopup(true);
    }
  };

  useEffect(() => {
    viewFeedbackDetailsId &&
      !userDetails?.isFeedbackUpdated &&
      dispatch(interviewerFeedbackDetailAction(viewFeedbackDetailsId));
  }, [dispatch, userDetails.isFeedbackUpdated, isUpdateFeedback, viewFeedbackDetailsId]);

  const handleAdd = () => {
    setEditInterviewId(null);
    onToggleSiderbar(true);
    document.body.classList.add('sidebar-open');
  };

  const handleSort = () => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        orderBy: prevState.orderBy === 'ASC' ? 'DESC' : 'ASC'
      };
    });
  };

  const searchDesignation = () => {
    if (designationText) {
      const sanitizeString = designationText.trim().toString();
      dispatch(getJobDesignationBySearch(sanitizeString));
    }
  };

  useEffect(() => {
    const modifiedDesignationOptions =
      searchData && searchData.length > 0 ? searchData : [{ Designation: MSG_TYPES.NO_RESULT }];
    setdesignationOptions(modifiedDesignationOptions);
  }, [searchData]);

  useEffect(() => {
    if (!designationText) {
      const modifiedDesiganationOptions =
        jobDesignationData &&
        jobDesignationData.find((designation) => designation.ID === feedbackData.jobDesignation);
      setDesignationText(modifiedDesiganationOptions);
    }
  }, [jobDesignationData, feedbackData]);

  useEffect(() => {
    if (hrRoundId) {
      dispatch(interviewerFeedbackDetailAction(hrRoundId));
    }
  }, [hrRoundId, dispatch]);

  useEffect(() => {
    if (ID && overallFeedback && Object.keys(overallFeedback).length !== 0) {
      const { Ratings } = overallFeedback;
      setFeedbackData({
        ...feedbackData,
        interviewId: overallFeedback.ID || '',
        feedbackNote: `${overallFeedback?.FeedbackNote || ''}`,
        result: `${overallFeedback?.Result || ''}`,
        overallGrade: `${Ratings?.OverAllGrades || ''}`,
        jobDesignation: Ratings?.Designation,
        package: Ratings?.Package,
        yearsOfExperience: Ratings?.YearsOfExperience,
        monthsOfExperience: Ratings?.MonthsOfExperience,
        candidateExpectation: Ratings?.CandidateExpectations
      });
    }
  }, [overallFeedback, ID]);

  return (
    <>
      {scheduleDetailPopup && (
        <ScheduleDetails
          show={scheduleDetailPopup}
          interviewRound={editInterviewId}
          onHide={() => setScheduleDetailPopup(false)}
        />
      )}

      <AddHrFeedback
        id={interviewRoundId}
        onHide={onToggleFeedbackPopup}
        show={showFeedbackPopup}
        disabled={viewFeedbackDetailsStatus === STATUS_INTERVIEW.CANCELLED}
        interviewType={typeInterview}
        feedbackData={feedbackData}
        interviewCategory={userDetails.InterviewCategory}
        confirmDialogMessage={
          <>
            Are you sure you want to submit the feedback for the{' '}
            <strong>
              {userDetails &&
                userDetails?.Candidate &&
                wordCapitalize(userDetails?.Candidate?.FirstName) +
                  ' ' +
                  wordCapitalize(userDetails?.Candidate?.LastName)}
            </strong>{' '}
            candidate?
          </>
        }
      />

      <InterviewDetails data={interviewDetails} onHide={onTogglePopup} show={showPopup} />

      {cancelConfirmBoxVisibility && (
        <ConfirmDialogWithReason
          bodyTitle={
            <>
              Are you sure you want to cancel the interview for{' '}
              <strong> &nbsp;{candidateName}?</strong>
            </>
          }
          isReasonRequired
          interviewId={interviewId}
          dialogHeader="Cancel interview"
          setInterviewId={setInterviewId}
          displayConfirmAction={cancelConfirmBoxVisibility}
        />
      )}

      <LogDetails
        data={
          interviews &&
          interviews?.data?.map((log) => ({
            ...log,
            DateModified: `${getFormattedDateView(log?.DateModified)} ${formatTime(
              new Date(log?.DateModified)
            )}`,
            DateCreated: `${getFormattedDateView(log?.DateCreated)} ${formatTime(
              new Date(log?.DateCreated)
            )}`
          }))
        }
        onHide={onToggleLogsPopup}
        show={showLogPopup}
        interviewId={ID}
      />

      <div>
        <div className="card candidate-outer">
          <div className="candidate-details">
            <div className="grid">
              <div className="col title">Candidate Name:</div>
              <div className="col content">
                {userDetails &&
                  userDetails?.Candidate &&
                  wordCapitalize(userDetails?.Candidate?.FirstName) +
                    ' ' +
                    wordCapitalize(userDetails?.Candidate?.LastName)}
              </div>
            </div>
            <div className="grid">
              <div className="col title">Resume:</div>
              <div
                className={classNames('col content', {
                  'cursor-pointer': userDetails?.Candidate?.ResumeOfCandidate?.FilePath,
                  'resume-link primary-color': userDetails?.Candidate?.ResumeOfCandidate?.FilePath
                })}
                data-tip={userDetails?.Candidate?.ResumeOfCandidate?.FilePath ? 'Resume' : ''}
                onClick={() => {
                  if (userDetails?.Candidate?.ResumeOfCandidate?.FilePath) {
                    openResumeLink(
                      `${pathBase}/${userDetails?.Candidate?.ID}/${userDetails?.Candidate?.ResumeOfCandidate?.FilePath}`
                    );
                  }
                }}>
                {userDetails?.Candidate?.ResumeOfCandidate?.FilePath ? (
                  <i className="pi pi-file-pdf"></i>
                ) : (
                  'Resume Not Found'
                )}
              </div>
            </div>
            <div className="grid">
              <div className="col title">Candidate Email:</div>
              <div className="col content">{userDetails?.Candidate?.Email || '--'}</div>
            </div>
            <div className="grid">
              <div className="col title">Candidate Mobile No:</div>
              <div className="col content">{userDetails?.Candidate?.Mobile || '--'}</div>
            </div>
          </div>

          <>
            <div className="hr-round-feedback-wrapper">
              <div
                className={`relative feedback-title ${
                  (userDetails?.Status === STATUS_INTERVIEW.HR_PENDING ||
                    userDetails?.Status === STATUS_INTERVIEW.HR_SCHEDULE ||
                    userDetails?.Status === STATUS_INTERVIEW.HR_RESCHEDULE ||
                    userDetails?.Status === STATUS_INTERVIEW.HR_COMPLETE) &&
                  'mb-2'
                }`}>
                <p className="">Feedback History Logs</p>
                <ViewButton
                  onClick={() => {
                    onToggleLogsPopup();
                  }}
                  title="View Logs"
                />
              </div>
            </div>
            {RENDER.No && (
              <form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-row-wrapper">
                  <div className="form-col">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label htmlFor="result" text="Result" isMandatory />
                        <Dropdown
                          name="result"
                          id="result"
                          optionLabel="name"
                          optionValue="code"
                          value={formik.values.result}
                          options={HR_OVERALL_FEEDBACK}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={disabled}
                          placeholder="Select result"
                          className={classNames({
                            'p-invalid': isFormFieldValid('result'),
                            'w-full': true
                          })}
                        />
                        {getFormErrorMessage('result')}
                      </div>
                    </div>
                  </div>

                  <div className="form-col">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="jobDesignation"
                          text={LABEL_TYPES.DESIGNATION}
                          isMandatory={
                            formik.values.result !== HR_OVERALL_FEEDBACK_STATUS['Not Selected']
                          }
                        />
                        <AutoComplete
                          field="Designation"
                          name="jobDesignation"
                          id="jobDesignation"
                          forceSelection
                          suggestions={designationOptions}
                          completeMethod={searchDesignation}
                          value={formik.values.jobDesignation}
                          onChange={(e) => {
                            if (typeof e.target.value === 'object') {
                              setDesignationText(e.target.value?.Designation);
                              formik.setFieldValue('jobDesignation', e.target.value?.Designation);
                            } else {
                              setDesignationText(e.target.value);
                              formik.setFieldValue('jobDesignation', e.target.value);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          placeholder="Select Designation"
                          className={classNames({
                            'p-invalid': isFormFieldValid('jobDesignation'),
                            'w-full': true
                          })}
                        />
                      </div>
                      {getFormErrorMessage('jobDesignation')}
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="form-group-outer flex column-gap">
                      <div className="custom-form-group flex-grow">
                        <Label
                          htmlFor="yearsOfExperience"
                          text={LABEL_TYPES.YEAR_EXPERIENCE}
                          isMandatory={
                            formik.values.result !== HR_OVERALL_FEEDBACK_STATUS['Not Selected']
                          }
                          isChildren
                          tooltip={`${
                            `candidate previous ${LABEL_TYPES.YEAR_EXPERIENCE} is` +
                            ' ' +
                            `${userDetails?.Candidate?.YearsOfExperience}`
                          }`}
                        />
                        <InputText
                          id="yearsOfExperience"
                          name="yearsOfExperience"
                          placeholder="0-30"
                          type="number"
                          value={formik.values.yearsOfExperience}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={classNames({
                            'p-invalid': isFormFieldValid('yearsOfExperience')
                          })}
                        />
                        {getFormErrorMessage('yearsOfExperience')}
                      </div>
                      <div className="custom-form-group flex-grow">
                        <Label
                          htmlFor="monthsOfExperience"
                          text={LABEL_TYPES.MONTH_EXPERIENCE}
                          isMandatory={
                            formik.values.result !== HR_OVERALL_FEEDBACK_STATUS['Not Selected']
                          }
                          isChildren
                          tooltip={`${
                            `candidate previous ${LABEL_TYPES.MONTH_EXPERIENCE} is` +
                            ' ' +
                            `${userDetails?.Candidate?.MonthsOfExperience}`
                          }`}
                        />
                        <InputText
                          id="monthsOfExperience"
                          name="monthsOfExperience"
                          placeholder="0-11"
                          type="number"
                          value={formik.values.monthsOfExperience}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={classNames({
                            'p-invalid': isFormFieldValid('monthsOfExperience')
                          })}
                        />
                        {getFormErrorMessage('monthsOfExperience')}
                      </div>
                    </div>
                  </div>

                  <div className="form-col">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="package"
                          text={LABEL_TYPES.PACKAGE}
                          isMandatory={
                            formik.values.result !== HR_OVERALL_FEEDBACK_STATUS['Not Selected']
                          }
                        />

                        <div className="p-inputgroup w-full">
                          <InputText
                            id="package"
                            name="package"
                            value={formik.values.package}
                            placeholder={LABEL_TYPES.PACKAGE}
                            onChange={formik.handleChange}
                            className={classNames({
                              'p-invalid': isFormFieldValid('package'),
                              'w-full': true
                            })}
                            onBlur={formik.handleBlur}
                            keyfilter={'money'}
                          />
                          <span className="p-inputgroup-addon add-candidate-lpa">LPA</span>
                        </div>
                      </div>
                      {getFormErrorMessage('package')}
                    </div>
                  </div>

                  <div className="form-col">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="candidateExpectation"
                          text={LABEL_TYPES.CANDIDATE_EXPECTATION}
                          isMandatory={
                            formik.values.result !== HR_OVERALL_FEEDBACK_STATUS['Not Selected']
                          }
                        />
                        <div className="p-inputgroup w-full">
                          <InputText
                            id="candidateExpectation"
                            name="candidateExpectation"
                            value={formik.values.candidateExpectation}
                            placeholder={LABEL_TYPES.CANDIDATE_EXPECTATION}
                            onChange={formik.handleChange}
                            className={classNames({
                              'p-invalid': isFormFieldValid('candidateExpectation'),
                              'w-full': true
                            })}
                            onBlur={formik.handleBlur}
                            keyfilter={'money'}
                          />
                          <span className="p-inputgroup-addon add-candidate-lpa">LPA</span>
                        </div>
                      </div>
                      {getFormErrorMessage('candidateExpectation')}
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="address"
                          text="Select an overall grade"
                          isMandatory={
                            formik.values.result !== HR_OVERALL_FEEDBACK_STATUS['Not Selected']
                          }
                        />
                        <Dropdown
                          name="overallGrade"
                          id="overallGrade"
                          optionLabel="name"
                          optionValue="code"
                          value={formik.values.overallGrade}
                          options={OVERALL_GRADE}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Select an overall grade"
                          className={classNames({
                            'p-invalid': isFormFieldValid('overallGrade'),
                            'w-full': true
                          })}
                        />
                      </div>
                      {getFormErrorMessage('overallGrade')}
                    </div>
                  </div>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label htmlFor="Note" text="Note" isMandatory />
                        <TextAreaWithCounterLimit
                          autoResize
                          placeholder="Add Note"
                          value={formik.values.feedbackNote}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          errorMessage={
                            formik.touched['feedbackNote'] ? formik.errors['feedbackNote'] : ''
                          }
                          className={classNames({
                            'p-invalid': isFormFieldValid('feedbackNote')
                          })}
                          id="feedbackNote"
                          name="feedbackNote"
                          htmlFor="address"
                          validationOnMinLimit
                          maxLimit={FEEDBACK_TEXTAREA_LIMIT.MAX}
                          rows={3}
                          cols={30}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-btn-wrapper">
                  <CustomButton type="submit" variant="contained" disabled={auth.isLoading}>
                    {BUTTON_TYPES.SAVE}
                  </CustomButton>
                </div>
              </form>
            )}
          </>
        </div>
      </div>

      {visibleRight && (
        <AddEditInterviewRound
          interviewRoundId={editInterviewId}
          onHide={onToggleSiderbar}
          show={visibleRight}
          technologies={userDetails?.Candidate?.UMap?.map((id) => id.MapT.ID)}
        />
      )}

      <AddEditFeedback
        id={viewFeedbackDetailsId}
        onHide={onViewFeedbackTogglePopup}
        disabled={viewFeedbackDetailsStatus === STATUS_INTERVIEW.CANCELLED}
        show={showViewFeedbackPopup}
        interviewType={typeInterview}
        interviewRoundMode={interviewRoundMode}
        candidateId={userDetails?.Candidate?.ID || ''}
        interviewRoundId={interviewRoundId}
        interviewRound={selectedRound}
        candidateName={candidateName}
        interviewCategory={userDetails.InterviewCategory}
        confirmDialogMessage={
          <>
            Are you sure you want to submit the feedback for the{' '}
            <strong>
              {userDetails?.Candidate?.FirstName
                ? wordCapitalize(userDetails?.Candidate?.FirstName)
                : ''}{' '}
              {userDetails?.Candidate?.LastName
                ? wordCapitalize(userDetails?.Candidate?.LastName)
                : ''}
            </strong>{' '}
            candidate?
          </>
        }
        ByHR
        setIsUpdateFeedback={setIsUpdateFeedback}
      />

      <DataTable
        onAdd={() => handleAdd()}
        onBack={() => navigate('/hr/interviews')}
        name={TABLE_NAME.INTERVIEW_ROUNDS}
        data={interviewList}
        exportData={interviewExport}
        columns={INTERVIEW_ROUND_COLUMNS}
        disablePaginator={true}
        onSort={handleSort}
      />
    </>
  );
};

export default InterviewRounds;
